import React from "react";
import Fade from "react-reveal/Fade";
import { useLanguage } from "../../languageContext";
// Icons

import handOnTrainingB from "../../images/servicesassets/knowledge/Blue/HandontrainingBlue.svg";
import remoteTrainingB from "../../images/servicesassets/knowledge/Blue/remotetrainingBlue.svg";
import realWorldApplicationsB from "../../images/servicesassets/knowledge/Blue/RealWorldApplicationsBlue.svg";
import detailedAssesmentB from "../../images/servicesassets/knowledge/Blue/DetailedAssessmentBlue.svg";

import handOnTrainingG from "../../images/servicesassets/knowledge/Green/HandontrainingGreen.svg";
import remoteTrainingG from "../../images/servicesassets/knowledge/Green/remotetrainingGreen.svg";
import realWorldApplicationsG from "../../images/servicesassets/knowledge/Green/RealWorldApplicationsGreen.svg";
import detailedAssesmentG from "../../images/servicesassets/knowledge/Green/DetailedAssessmentGreen.svg";

import handOnTrainingR from "../../images/servicesassets/knowledge/Red/HandontrainingRed.svg";
import remoteTrainingR from "../../images/servicesassets/knowledge/Red/remotetrainingRed.svg";
import realWorldApplicationsR from "../../images/servicesassets/knowledge/Red/RealWorldApplicationsRed.svg";
import detailedAssesmentR from "../../images/servicesassets/knowledge/Red/DetailedAssessmentRed.svg";

//training program images

const TrainingSolution = () => {
  const currentLanguage = useLanguage();
  return (
    <div className="section m-top-10">
      <div className="container">
        <div className="knowledge-training-solutions">
          <div className="heading knowledge-training-head">
            <Fade bottom>KEY FEATURES OF TRAINING</Fade>
          </div>
          <div className="wrapper-grid">
            <div className="element">
              <img
                src={
                  currentLanguage === "en"
                    ? handOnTrainingG
                    : currentLanguage === "in"
                    ? handOnTrainingB
                    : currentLanguage === "ja"
                    ? handOnTrainingR
                    : handOnTrainingG
                }
                alt="Knowledge Management Icon"
              ></img>
              <div className="heading semi-title m-top-10px">Hands-on Training</div>
              {/* <div className="para semi-para">Brings more cultural diversity to your team</div> */}
            </div>
            <div className="element">
              <img
                src={
                  currentLanguage === "en"
                    ? remoteTrainingG
                    : currentLanguage === "in"
                    ? remoteTrainingB
                    : currentLanguage === "ja"
                    ? remoteTrainingR
                    : remoteTrainingG
                }
                alt="Knowledge Management Icon"
              ></img>
              <div className="heading semi-title m-top-10px">Remote Training</div>
              {/* <div className="para semi-para">Does not leave any room for misinterpretation</div> */}
            </div>
            <div className="element">
              <img
                src={
                  currentLanguage === "en"
                    ? realWorldApplicationsG
                    : currentLanguage === "in"
                    ? realWorldApplicationsB
                    : currentLanguage === "ja"
                    ? realWorldApplicationsR
                    : realWorldApplicationsG
                }
                alt="Knowledge Management Icon"
              ></img>
              <div className="heading semi-title m-top-10px">Real World Applications</div>
              {/* <div className="para semi-para">We manufacture and customize lighting fixtures</div> */}
            </div>
            <div className="element">
              <img
                src={
                  currentLanguage === "en"
                    ? detailedAssesmentG
                    : currentLanguage === "in"
                    ? detailedAssesmentB
                    : currentLanguage === "ja"
                    ? detailedAssesmentR
                    : detailedAssesmentG
                }
                alt="Knowledge Management Icon"
              ></img>
              <div className="heading semi-title m-top-10px">Assesment</div>
              {/* <div className="para semi-para">We manufacture and customize lighting fixtures</div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrainingSolution;
